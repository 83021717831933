export function triggerPurchaseEvent(
  subscriptionData,
  discountCode,
  facilityCode,
  timePeriods,
  email,
  phoneNumber
) {
  const { discount, plan, quantity, id, items } = subscriptionData;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "purchase",
    discount_coupon: discountCode || "",
    organization_coupon: facilityCode || "",
    email: email,
    phone_number: phoneNumber,
    ecommerce: {
      currency: "USD",
      value: getTotalAmount(discount, plan, quantity),
      tax: 0,
      shipping: 0,
      affiliation: "MM web portal",
      transaction_id: id,
      coupon: "",
      items: [
        {
          item_name: "Subscription - " + timePeriods,
          item_id: items.data[0].id,
          price: plan.amount / 100,
          discount: discount ? discount.coupon.percent_off / 100 : 0,
          item_brand: "N/A",
          item_category: timePeriods,
          item_category2: facilityCode ? "B2B" : "B2C",
          item_variant: "N/A",
          quantity: quantity,
        },
      ],
    },
  });
}

export function triggerFacilityPurchaseEvent(subscriptionData) {
  const { discount, plan, quantity, id, items, latest_invoice } =
    subscriptionData;
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "purchase",
    discount_coupon: 0,
    ecommerce: {
      currency: "USD",
      value: latest_invoice?.total / 100,
      tax: 0,
      shipping: 0,
      affiliation: "MM web portal",
      transaction_id: id,
      coupon: "",
      items: [
        {
          item_name: "Subscription - Month",
          item_id: items.data[0].id,
          price: plan.amount / 100,
          discount: discount ? discount.coupon.percent_off / 100 : 0,
          item_brand: "N/A",
          item_category: "Mont",
          item_category2: "facility",
          item_variant: "N/A",
          quantity: quantity,
        },
      ],
    },
  });
}

export function triggerAccountRegisteredEvent(registrationMethod) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "account_registered",
    registration_method: registrationMethod,
  });
}

export function triggerFormSubmissionEvent(email, phoneNumber) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "form_submission",
    form_name: "sign_up",
    email: email,
    phone_number: phoneNumber,
  });
}

function getTotalAmount(discount, plan, quantity) {
  const accessAmount = plan.amount / 100;
  let totalAmount = accessAmount * quantity;

  if (discount?.coupon.percent_off) {
    const discountAmount = (totalAmount * discount.coupon.percent_off) / 100;
    totalAmount -= discountAmount;
  }

  return parseFloat(totalAmount.toFixed(2));
}
